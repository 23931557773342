<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
      </div>
    </div>
    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Withdrawal Report -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <TitleComponent
                  :title="$t($route.meta.title)"
                  :date-string="dateFilter"
                />
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :title="$t('page.report.withdrawal.todaySales')"
                :is-up="cardData.sales_today.is_up"
                :percentage="cardData.sales_today.percent"
                :total="cardData.sales_today.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
              <Card
                :title="$t('page.report.withdrawal.totalSales')"
                :is-up="cardData.sales_total.is_up"
                :percentage="cardData.sales_total.percent"
                :total="cardData.sales_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
              <Card
                :title="$t('page.report.withdrawal.balance')"
                :total="cardData.balance_total"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
            </div>
          </div>
          <!-- END: Withdrawal Report -->

          <!-- BEGIN: Daily Sales -->
          <TableComponent
            v-if="false"
            :date-string="dateFilter"
            :title="$t('page.report.withdrawal.dailySales')"
            :header="dailySalesHeader"
            :is-show-search="true"
            :search-options="dateOptions"
            :is-not-empty="dailySalesData.length > 0"
            :meta-data="metaDataDaily"
            @change-filter="changeFilterDaily($event)"
          >
            <tr v-for="(data, index) in dailySalesReportData" :key="index">
              <td>{{ data.date_time }}</td>
              <td><Currency :total-in-string="data.sales_total" /></td>
              <td><Currency :total-in-string="data.gross_profit" /></td>
              <td class="text-center">{{ data.transaction_total }}</td>
              <td class="text-center">{{ data.product_total }}</td>
            </tr>
          </TableComponent>
          <!-- END: Daily Sales -->
        </div>
      </div>
    </div>
    <div class="mt-8 flex w-full justify-end">
      <div class="flex">
        <ExportCSVComponent @export="downloadWithdrawList" />
      </div>
    </div>
    <!-- BEGIN: Withdrawal Report -->
    <TableComponent
      :date-string="dateFilter"
      :title="$t('page.report.withdrawal.withdrawalReport')"
      :header="withdrawalReportHeader"
      :vue-select-data="channelWithdrawData"
      vue-select-label="name"
      :is-show-search="true"
      :is-not-empty="withdrawalReportData.length > 0"
      :meta-data="metaData"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(data, index) in withdrawalReportData" :key="index">
        <td class="text-center">{{ data.date_time }}</td>
        <td class="text-center">
          <Currency
            :total-in-string="data.amount"
            wrap-class="justify-center"
          />
        </td>
        <td class="text-center">
          <Currency
            :total-in-string="data.total_mdr"
            wrap-class="justify-center text-red-500"
            prefix-text="-"
          />
        </td>
        <td class="text-center">
          <Currency
            :total-in-string="data.pos_admin_fee"
            wrap-class="justify-center text-red-500"
            prefix-text="-"
          />
        </td>
        <td class="text-center">
          <Currency
            :total-in-string="data.admin_fee"
            wrap-class="justify-center text-red-500"
            prefix-text="-"
          />
        </td>
        <td class="text-center">
          <Currency
            :total-in-string="
              String(
                data.amount -
                  (data.total_mdr + data.pos_admin_fee + data.admin_fee)
              )
            "
            wrap-class="justify-center"
          />
        </td>
        <td class="text-center">{{ data.withdrawal_method ?? '-' }}</td>
        <td class="text-center">{{ data.send_to ?? '-' }}</td>
        <td v-if="data.status" class="text-center">
          <Tippy
            tag="div"
            class="cursor-pointer rounded-md p-1 uppercase text-white"
            :class="[data.status === 'completed' ? 'bg-success' : 'bg-danger']"
            :content="data.status"
          >
            {{ data.status }}
          </Tippy>
        </td>
        <td v-else>-</td>
      </tr>
    </TableComponent>
    <!-- END: Withdrawal Report -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import TableComponent from '@/components/TableComponent.vue'
import TitleComponent from '@/components/TitleComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Currency from '@/components/Currency.vue'
import Card from '@/components/Card.vue'
import SaleIcon from '@/assets/svg/sale.svg'

export default {
  name: 'WithdrawalPage',
  components: {
    Breadcrumb,
    TableComponent,
    TitleComponent,
    ExportCSVComponent,
    Currency,
    Card,
    SaleIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({
    channelWithdrawIds: ['1'],
    channelWithdrawData: [],
    dailySalesHeader: [
      {
        item: 'page.report.withdrawal.tableHeaders.time',
        customClass: '',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.sales',
        customClass: '',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.grossProfit',
        customClass: '',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.totalTransactions',
        customClass: '',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.totalProducts',
        customClass: 'text-center',
      },
    ],
    dailySalesData: [],
    withdrawalReportHeader: [
      {
        item: 'page.report.withdrawal.tableHeaders.time',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.totalWithdrawal',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.mdrFee',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.posAdminFee',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.adminFee',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.totalTransfered',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.withdrawalMethod',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.sendTo',
        customClass: 'text-center',
      },
      {
        item: 'page.report.withdrawal.tableHeaders.status',
        customClass: 'text-center',
      },
    ],
    withdrawalReportData: [],
    cardData: {
      sales_today: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      balance_total: 0,
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaDataDaily: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataDaily: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaDataWithdrawChannel: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataWithdrawChannel: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    dateOptions: [],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
        this.init()
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    this.init()
    this.getAllWithdrawChannel()
  },
  methods: {
    init() {
      this.getSummary()
      this.getWithdrawList()
    },
    async getAllWithdrawChannel() {
      await this.store
        .dispatch('withdraw/withdrawChannel', {
          order: this.filterDataWithdrawChannel.order,
          page: this.filterDataWithdrawChannel.page,
          take: this.filterDataWithdrawChannel.take,
          q: this.filterDataWithdrawChannel.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            // this.channelWithdrawData = responseData.data
            this.channelWithdrawIds = responseData.data.map(
              (channel) => channel.id
            )
          }
          if (responseData.meta) {
            this.metaDataWithdrawChannel = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async getSummary() {
      this.isLoading = true

      await this.$store
        .dispatch('withdraw/userWithdrawSummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.sales_today = responseData.sales_today
            this.cardData.sales_total = responseData.sales_total
            this.cardData.balance_total = responseData.balance_total
          }
          this.message = response.data.message
          this.showToast('success')
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async getDailySales() {
      await this.store
        .dispatch('sales/salesDaily', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterDataDaily.page,
          take: this.filterDataDaily.take,
          search_date: this.filterDataDaily.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.dailySalesData = responseData.data
            if (this.filterDataDaily.search === '') {
              this.dateOptions = responseData.data.map((item) =>
                this.formatDate(item.date_time)
              )
            }
          }
          if (responseData.meta) {
            this.metaDataDaily = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async getWithdrawList() {
      await this.store
        .dispatch('withdraw/userWithdrawList', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterData.page,
          take: this.filterData.take,
          channel_ids: this.channelWithdrawIds.toString(),
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.withdrawalReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async downloadWithdrawList(fileType) {
      await this.store
        .dispatch('withdraw/userWithdrawDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'withdraw_list.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getWithdrawList()
    },
    changeFilterDaily(event) {
      this.filterDataDaily = event.filterData
      this.getDailySales()
    },
  },
}
</script>
